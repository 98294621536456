import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Container } from '@material-ui/core';
import ViewResultItem from "./ViewResultItem";
const useStyles = makeStyles((theme) => ({
  root:{
    paddingBottom: "10px"

  },
  modalTitle: {
    padding: "1.5rem 2rem 1.5rem",
    color: "#005CB2",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    }
  },
  modalButton: {
    display: 'flex',
    margin: 'auto',
    marginTop: '2rem',
    height: '35px',
    width: '100px',
    fontSize: '16px',
    textTransform: 'none',
    '@media screen and (min-width: 768px)': {
      height: '45px',
      width: '150px',
      borderRadius: '10px',
      fontSize: '18px',
    },
    '@media screen and (min-width: 1024px)': {
      height: '50px',
      width: '180px',
      fontSize: '22px',
      marginBottom: '0'
    }
  },
}));

const ViewResult = ({ onClose, data, selected }) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Typography variant="h4" component="h1" align="center" className={classes.modalTitle}>Đáp án</Typography>
      {data.map((item, index) => (
        <ViewResultItem data= {item} selected = {selected}/>
      ))}
      <Button variant="contained" color="primary" className={classes.modalButton} onClick={onClose}>Đóng</Button>
    </Container>
  );
}
export default ViewResult;