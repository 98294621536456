import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import Image from "../../../common/Image"
import TestItemChilren from "./TestItemChilren"

const useStyles = makeStyles((theme) => ({
  root:{
    padding: "10px 0",
  },
  mondai: {
    fontSize: '30px',
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
    }
  },
  text: {
    fontSize: '22px',
    lineHeight: '1.8',
    padding: '20px 0',
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    }
  },
}));

const TestItem = ({ data, selected, setSelected }) => {

  const classes = useStyles();
  return (
      <Container className={classes.root}>
        <Typography  className={classes.mondai}>{data.mondai}</Typography>
        {/* {data.img && data.img.src !== '' && <Image {...data.img} />} */}
        <Typography className={classes.text}>{data.noi_dung}</Typography>
        {data.questions.map((item, index) => {
            return(
                <div>
                    <TestItemChilren data = {item} mondai={data.mondai} selected = {selected} setSelected = {setSelected}/>
                </div>
            )})}
      </Container>
  );
}
export default TestItem;