import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Container, Grid } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Alink from "../../../common/ALink"
import { isLoggedIn } from "../../../../utils/auth"
import {
  addTestingRecord,
  formatTime,
  getCorrectAnswers,
  getTotalQuestion,
} from "../../../../api/testingHistory.service"
import { addOrUpdateLearningProgressItem } from "../../../../api/learningProgress.service"

const useStyles = makeStyles(theme => ({
  title: {
    padding: "1.5rem 2rem 1.5rem",
    color: "#005CB2",
    fontSize: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  result: {
    fontSize: "20px",
    padding: "5px 0",
  },
  gridcontent: {
    paddingTop: "32px",
    paddingBottom: "20px",
    "& span": {
      fontSize: "16px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% + 30px)",
      margin: "-12px",
      fontSize: "16px",
    },
  },
  link: {
    textDecoration: "none",
  },
}))

const Result = props => {
  const classes = useStyles()
  const { data, selected, onReset, onPlay, onAnswersCheck, time } = props
  useEffect(() => {
    if (isLoggedIn()) {
      addTestingRecord(selected, data, time);
      addOrUpdateLearningProgressItem(data.id, data.topicId, "quiz", getCorrectAnswers(data, selected), getTotalQuestion(data));
    }
  }, [isLoggedIn()]);

  return (
    <Container className={classes.root}>
      <Typography
        className={classes.title}
        variant="h4"
        component="h1"
        align="center"
      >
        Kết quả bài test
      </Typography>
      <Typography className={classes.result} align="center">
        {getCorrectAnswers(data, selected)} / {getTotalQuestion(data)}
      </Typography>
      <Typography className={classes.result} align="center">
        {Math.round(
          ((getCorrectAnswers(data, selected) * 100) / getTotalQuestion(data)) *
            100
        ) / 100}
        %
      </Typography>
      <Typography align="center" className={classes.endResult3}>
        Thời gian thực hiện: <strong>{formatTime(time)}</strong>
      </Typography>
      <Grid
        container
        className={classes.gridcontent}
        spacing={3}
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Button variant="contained" color="primary" onClick={onAnswersCheck}>
            Kiểm tra đáp án
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            width="120px"
            onClick={onReset}
          >
            Làm lại
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            width="120px"
            onClick={onPlay}
          >
            Làm bài khác
          </Button>
        </Grid>
        <Grid item>
          <Alink to="/topics" className={classes.link}>
            <Button variant="contained" color="primary">
              Khóa học khác
            </Button>
          </Alink>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Result
