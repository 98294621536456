import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  title: {
    padding: "10px",
    color: "#005CB2",
    fontSize: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  content: {
    margin: "20px 10px"
  },
  subTitle1: {
    fontSize: '22px',
    marginBottom: '20px',
    fontWeight: '500',
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    }
  },
  startButton: {
    textTransform: "none",
    display: 'flex',
    margin: "20px auto",
    height: '45px',
    width: '150px',
    fontSize: "20px"
  },
}));

const Start = ({ data, onQuizStart }) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Typography variant="h4" component="h1" align="center" className={classes.title}>{data.name}</Typography>
      <div className={classes.content}>
        {data.subtitle.map((item) => {
          return (
            <Typography align="center" className={classes.subTitle1}>{item}</Typography>
          )
        })}
      </div>
      <Button variant="contained" color="primary" className={classes.startButton} onClick={onQuizStart}>
        Bắt đầu
          </Button>
    </Container>
  );
}

export default Start;