import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Container } from '@material-ui/core';
import Parser from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  modalQuestion: {
    color: '#212121',
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: "10px",
    marginTop: '2rem',
    textAlign: "justify",
    lineHeight: "23px",
    '@media screen and (min-width: 768px)': {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '26px',
    },
    '@media screen and (min-width: 1024px)': {
      fontSize: '22px',
    }
  },
  modalYourAnswer: {
    color: "#12ED1B",
    fontSize: '16px',
    marginLeft: '1rem',
    paddingBottom: "10px",
    '@media screen and (min-width: 768px)': {
      fontSize: '18px',
    },
    '@media screen and (min-width: 1024px)': {
      fontSize: '20px',
    }
  },
  modalCorrecAnswer: {
    color: '#ED3535',
    fontSize: '16px',
    marginLeft: '1rem',
    '@media screen and (min-width: 768px)': {
      fontSize: '18px',
    },
    '@media screen and (min-width: 1024px)': {
      fontSize: '20px',
    }
  }
}));


const ViewResultItem = ({data, selected }) => {
  const classes = useStyles();
  return (
    <Container>
          <Typography variant="h4" component="h1" >{data.mondai}</Typography>
          {data.questions.map((item, index) => (
            <Box key={index}>
              <Typography className={classes.modalQuestion}>{Parser(item.question)}</Typography>
              <Typography className={classes.modalYourAnswer}>Đáp án đã chọn: {selected.get(item.question+data.mondai)}</Typography>
              {selected.get(item.question+data.mondai) !== item.correct && <Typography className={classes.modalCorrecAnswer}>Đáp án đúng: {item.correct}</Typography>}
            </Box>
          )
          )}
    </Container>
  );
}

export default ViewResultItem;