import React, { useState} from 'react';
import Container from "@material-ui/core/Container"
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Start from '../sections/Quiz/Start'
import Test from '../sections/Quiz/Test'
import Result from '../sections/Quiz/Result'
import ViewResult from '../sections/Quiz/ViewResult'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    padding: "50px 0",
    background: "#ffffff99",
  },
  content: {
    margin:"0 auto",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    }
  },
}));
const Quiz = ({ arrData }) => {
  const [step, setStep] = useState(1)
  const [selected, setSelected] = useState(new Map());
  const [showModal, setShowModal] = useState(false)
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [index, setIndex] = useState(0)
  const [learnedList, setLearnedList] = useState([0])

  const data = arrData[index]

  const quizStartHandler = () => {
    setStep(2)
    setStartTime(new Date())
  }
  const resetClickHandler = () => {
    setStep(2)
    setShowModal(false)
    setSelected(new Map())
    setStartTime(new Date())
  }
  const playClickHandler = () => {
    setStep(2)
    if(learnedList.length == arrData.length){
      learnedList.length = 0;
    }
    let indexRandom = 0
    while(learnedList.indexOf(indexRandom) !== -1) {
      indexRandom = Math.floor(Math.random() * arrData.length)
    }
    setIndex(indexRandom)
    learnedList.push(indexRandom)
    setLearnedList(learnedList)
    setShowModal(false)
    setSelected(new Map())
    setStartTime(new Date())
  }
  const quizResultHandler = () => {
    setStep(3)
    setEndTime(new Date())
  }
  const classes = useStyles()
  return (
      <Container className={classes.root}>
        <Paper elevation={3} className={classes.content}>
          {step === 1 && <Start data={data} onQuizStart={quizStartHandler} />}
          {step === 2 &&  (
            <Test data={data} onClickResult={quizResultHandler} selected={selected} setSelected={setSelected} />
          )}
          {step === 3 &&
            <Result
              data={data}
              selected={selected}
              onReset={resetClickHandler}
              onPlay= {playClickHandler}
              onAnswersCheck={() => setShowModal(true)}
              time={Math.round((endTime-startTime)/(1000))}
            />}
          {showModal &&
            <ViewResult
              data={data.content}
              selected={selected}
              onClose={() => setShowModal(false)}
            />}
        </Paper>
      </Container>
  )
}
export default Quiz;
