import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Container } from '@material-ui/core';
import Image from "../../../common/Image"
import Parser from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10px",
    padding: "0px",
    [theme.breakpoints.up("md")]: {
      padding: "0 20px",
    }
  },
  question: {
    paddingTop: '10px',
    fontSize: "20px",
    fontWeight: 'normal',
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    }
  },
  img: {
    width: "50%",
  },
  answers_label: {
    fontWeight: 'normal',
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    }
  },
  radio: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: "10px 30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }
  },
  radioChildRow: {

    width: "50%",
    marginRight: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  radioChildColumn: {

    width: "100%",
  }
}));

const TestItemChilren = ({ data, mondai, selected, setSelected }) => {
  const changeHandler = (e) => {
    setSelected(selected.set(data.question + mondai, e.target.value))
  }
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Typography className={classes.question}>{Parser(data.question)}</Typography>
      {data.img && data.img.src !== '' && <Image {...data.img} className={classes.img} />}
      <RadioGroup name="choice" className={classes.radio} onChange={changeHandler}>
        {data.answers.map((item, index) => {
          return (
            <FormControlLabel className={
              item.length <= "10" ?
                classes.radioChildRow
                : classes.radioChildColumn
            } name={index} value={item} control={<Radio />}
              label={
                <Typography className={classes.answers_label}>{item}
                </Typography>} />
          )
        })}
      </RadioGroup>
    </Container>
  );
}
export default TestItemChilren;