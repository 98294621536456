import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Container } from '@material-ui/core';
import Audio from "../../../common/Audio"
import TestItem from "./TestItem"


const useStyles = makeStyles((theme) => ({
  root:{
    width: "100%",
    padding: "20px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px",
    }
  },
  title:{
    padding: "10px",
    color: "#005CB2",
    fontSize: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  resultButton: {
    textTransform: "none",
    display: 'flex',
    margin: "20px auto",
    height: '45px',
    width: '130px',
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}));

const Test = ({ data, onClickResult, selected, setSelected }) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Typography variant="h4" component="h1" align="center" className={classes.title} >{data.name}</Typography>
      <Container >
      {data.audio && data.audio.src !== '' && <Audio className={classes.audio} {...data.audio} />}
      </Container >
      <Container className={classes.content}>
        {data.content.map((item, index) => {
          return (
            <div>
              <TestItem  data = {item} selected = {selected} setSelected = {setSelected} />
            </div>
          )})
        }
        <Button variant="contained" color="primary" className={classes.resultButton} onClick={onClickResult}>Nộp bài</Button>
      </Container>
    </Container>
  );
}
export default Test;