import React from "react"
import { graphql } from "gatsby"
import withRoot from "../../components/shell/withRoot"
import Quiz from "../../components/learning/pageContents/Quiz"

function TestPage({ data, params }) {
  const { topicId } = params
  let quizData = []
  data.allQuizJson.nodes.forEach(quizItem =>{
    quizItem.content.forEach(contenItem =>{
        contenItem.questions.forEach(questionsItem =>{
          if(!questionsItem.img.src.includes("quiz/")){
            questionsItem.img.src = "quiz/" + questionsItem.img.src
          }
        } )
    })
    quizData.push(quizItem)
  })
  return (
    <React.StrictMode>
      {topicId && (
        <Quiz
          arrData={quizData.filter(i => i.topicId === topicId)}
        />
      )}
    </React.StrictMode>
  )
}

export default withRoot(TestPage)
export const query = graphql`
  {
    allQuizJson {
      nodes {
        content {
          mondai
          noi_dung
          questions {
            answers
            correct
            question
            img {
              alt
              src
            }
          }
        }
        id
        name
        subtitle
        topicId
      }
    }
  }
`
